import * as React from "react";

function IconBrandSpotify({
  size = 24,
  color = "currentColor",
  stroke = 2,
  ...props
}) {
  return <svg className="icon icon-tabler icon-tabler-brand-spotify" width={size} height={size} viewBox="0 0 24 24" strokeWidth={stroke} stroke={color} fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx={12} cy={12} r={9} /><path d="M8 11.973c2.5-1.473 5.5-.973 7.5.527" /><path d="M9 15c1.5-1 4-1 5 .5" /><path d="M7 9c2-1 6-2 10 .5" /></svg>;
}

export default IconBrandSpotify;