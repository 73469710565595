import * as React from "react";

function IconWifi1({
  size = 24,
  color = "currentColor",
  stroke = 2,
  ...props
}) {
  return <svg className="icon icon-tabler icon-tabler-wifi-1" width={size} height={size} viewBox="0 0 24 24" strokeWidth={stroke} stroke={color} fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={12} y1={18} x2={12.01} y2={18} /><path d="M9.172 15.172a4 4 0 0 1 5.656 0" /></svg>;
}

export default IconWifi1;